<script>
import BVForm from "@/components/BVForm/BVForm.vue";

export default {
  components: {
    BVForm,
    InformacionGeneral: () => import("./secciones/InformacionGeneralView.vue"),
    ConductorAsignado: () => import("./secciones/ConductorAsignadoView.vue"),
    MantenimentosRealizados: () =>
      import("./secciones/MantenimentosRealizadosView.vue"),
    IncidentesYAccidentes: () =>
      import("./secciones/IncidentesYAccidentesView.vue"),
    Soat: () => import("./secciones/SoatView.vue"),
    Poliza: () => import("./secciones/PolizaView.vue"),
    PolizaExtracontractual: () =>
      import("./secciones/PolizaExtracontractual.vue"),
    PolizaContractual: () => import("./secciones/PolizaContractual.vue"),
    PolizaTodoRiesgo: () => import("./secciones/PolizaTodoRiesgo.vue"),
    TecnicomecanicaYDeGases: () =>
      import("./secciones/TecnicomecanicaYDeGasesView.vue"),
    Impuestos: () => import("./secciones/ImpuestosView.vue"),
    RegistroFotografico: () =>
      import("./secciones/RegistroFotograficoView.vue"),
    TarjetaDeOperaciones: () => import("./secciones/TarjetaDeOperaciones.vue"),
    VehiculoPropietario: () => import("./secciones/VehiculoPropietario.vue"),
    GestionDeProveedores: () => import("./secciones/GestionDeProveedores.vue"),
  },
  data() {
    return {
      modelPlaca: [
        {
          placa: "",
          state: "",
          placeholder: "ABC123",
          name: "placa",
        },
      ],
      isFound: false,
      vehiculo: null,
      secciones: [
        {
          name: "InformacionGeneral",
          text: "Información general del vehículo",
        },
        { name: "TarjetaDeOperaciones", text: "Tarjeta de operaciones" },
        { name: "VehiculoPropietario", text: "Propietario del vehículo" },
        { name: "ConductorAsignado", text: "Conductor asignado" },
        { name: "GestionDeProveedores", text: "Gestión de proveedores" },
        { name: "MantenimentosRealizados", text: "Mantenimientos realizados" },
        {
          name: "IncidentesYAccidentes",
          text: "Incidentes, accidentes y comparendos",
        },
        { name: "Soat", text: "Soat" },
        { name: "Poliza", text: "Póliza de responsabilidad civil" },
        { name: "PolizaExtracontractual", text: "Póliza Extracontractual" },
        { name: "PolizaContractual", text: "Póliza Contractual" },
        { name: "PolizaTodoRiesgo", text: "Póliza Todo Riesgo" },
        {
          name: "TecnicomecanicaYDeGases",
          text: "Revision técnico mecánica y de gases",
        },
        { name: "Impuestos", text: "Impuestos" },
        { name: "RegistroFotografico", text: "Registro fotográfico" },
      ],
      selectedSeccion: {
        name: "InformacionGeneral",
        text: "Información general del vehículo",
      },
    };
  },
  mounted() {
    this.selectedSeccion = this.secciones[0];
  },
  methods: {
    searchingPlaca({ value, idx }) {
      const placa = value.toLowerCase();

      if (placa.length == 6) {
        this.modelPlaca[idx].state = "disabled";
        this.$store.getters
          .fetchGet({ path: `Vehiculo/Placa/${placa}` })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status == 404) {
              throw new Error("No se encontró el vehículo");
            }
          })
          .then(async (vehiculo) => {
            this.vehiculo = vehiculo;

            this.modelPlaca[idx].state = "success";
            this.modelPlaca[idx].value = vehiculo.placa;
            this.isFound = true;
          })
          .catch((error) => {
            this.modelPlaca[idx].state = "error";
            this.modelPlaca[idx].errorMessages = [error.message];
          });
      } else {
        (this.modelPlaca[idx].state = ""),
          (this.modelPlaca[idx].errorMessages = []);
        this.isFound = false;
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="card p-3 rounded rounded-4">
      <h3 class="mb-3">Hoja de vida vehículo</h3>
      <div class="d-flex media-968">
        <div class="w-25 px-2 media-w-100">
          <BVForm
            :schema="modelPlaca"
            hide-footer
            :observer="['placa']"
            @watcher="searchingPlaca"
          >
            <template #header>
              <p class="m-0 mb-2 h4">Placa del vehiculo</p>
            </template>
            <template #warning>
              <div class="my-2">
                <router-link
                  target="_blank"
                  :to="{ name: 'RegistroDeVehiculos' }"
                >
                  ir a crear un vehiculo
                </router-link>
              </div>
            </template>
          </BVForm>
          <div class="btn-group-h">
            <p class="m-0 mb-2 h4 w-100">Secciones</p>
            <button
              v-for="seccion in secciones"
              :key="seccion.name"
              @click="selectedSeccion = seccion"
              class="btn btn-primary w-100 my-1 rounded"
              :class="{ 'btn-primary': seccion.name == selectedSeccion.name }"
            >
              {{ seccion.text }}
            </button>
          </div>
        </div>
        <div class="w-100 px-4">
          <component
            v-if="vehiculo || selectedSeccion.name == 'GestionDeProveedores'"
            :is="selectedSeccion.name"
            :vehiculo="vehiculo"
          />
          <div
            v-else
            class="w-100 h-100 d-flex justify-content-center align-items-center"
          >
            <div class="h2">ingrese la placa del vehiculo</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 968px) {
  .media-968 {
    flex-direction: column;
  }
  .media-w-100 {
    width: 100% !important;
  }
  .btn-group-h {
    display: flex;
  }

  .media-968 button {
    width: 33% !important;
  }
}
</style>
./secciones/polizaExtracontractual.vue./secciones/polizaExtracontractual.vue./secciones/PolizaExtracontractual.vue./secciones/PolizaExtracontractual.vue
